<template>
    <v-container>
      <v-row align="center" justify="space-between">
        <h1>Administração de Notícias</h1>
        <v-btn color="error" @click="logout">Logout</v-btn>
      </v-row>
  
      <!-- LISTA DE NOTÍCIAS --> 
      <v-row
        v-for="item in displayedNews"
        :key="item.id"
        align="center"
      >
        <v-col cols="3" class="d-none d-md-flex">
          <img :src="item.imageUrl" width="100%" style="height: fit-content;" />
        </v-col>
  
        <v-col cols="12" md="6">
          <small>{{ formatDate(item.date) }}</small>
          <h3 style="margin-bottom: 10px;">{{ item.title_pt }}</h3>
          <p>{{ item.content_pt }}</p>
        </v-col>
  
        <v-col cols="12" md="3" class="text-center">
          <v-btn color="primary" @click="editNews(item)">Editar</v-btn>
          <v-btn color="error" @click="deleteNews(item.id)">Remover</v-btn>
        </v-col>
  
        <v-col cols="12">
          <hr>
        </v-col>
      </v-row>
  
      <!-- SKELETON LOADER (LOADING) -->
      <v-row v-if="loading">
        <v-col cols="3" class="d-none d-md-flex">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader type="text"></v-skeleton-loader>
          <v-skeleton-loader type="text, text, text"></v-skeleton-loader>
        </v-col>
      </v-row>
  
      <!-- BOTÃO CARREGAR MAIS -->
      <v-row v-if="!loading && canLoadMore">
        <v-col cols="12" class="text-center">
          <v-btn @click="loadMoreNews" color="#f8a600">
            Carregar mais notícias
          </v-btn>
        </v-col>
      </v-row>
  
      <!-- DIÁLOGO DE EDIÇÃO -->
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Editar Notícia</span>
          </v-card-title>
  
          <v-card-text>
            <v-text-field
              label="Título"
              v-model="editedNews.title"
              outlined
              dense
            />
            <v-text-field
              label="Título (PT)"
              v-model="editedNews.title_pt"
              outlined
              dense
            />
            <v-textarea
              label="Conteúdo"
              v-model="editedNews.content"
              outlined
              dense
            />
            <v-textarea
              label="Conteúdo (PT)"
              v-model="editedNews.content_pt"
              outlined
              dense
            />
            <v-text-field
              label="URL da Imagem"
              v-model="editedNews.imageUrl"
              outlined
              dense
            />
            <v-text-field
              label="Fonte"
              v-model="editedNews.source"
              outlined
              dense
            />
            <v-text-field
              label="URL"
              v-model="editedNews.url"
              outlined
              dense
            />
            <v-switch
              label="Destaque"
              v-model="editedNews.highlight"
            />
            <v-switch
              label="Publicado"
              v-model="editedNews.publish"
            />
  
            <!-- MENU + DATE PICKER -->
            <v-menu
              v-model="menu"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <!-- ACTIVATOR -->
              <template #activator="{ props }">
                <v-text-field
                  v-bind="props"
                  :value="editedNews.date ? formatDate(editedNews.date) : ''"
                  label="Data"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </template>
  
              <!-- DATE PICKER -->
              <v-date-picker
                v-model="editedNews.date"
                no-title
                @change="handleDatePickerInput"
              ></v-date-picker>
            </v-menu>
          </v-card-text>
  
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn color="primary" text @click="saveNews">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import { ref } from 'vue';
  import {
    collection,
    doc,
    query,
    orderBy,
    getDocs,
    updateDoc,
    deleteDoc
  } from 'firebase/firestore';
  import { getAuth, signOut } from 'firebase/auth';
  import { useRouter } from 'vue-router';
  
  import { db } from '@/firebase';
  
  export default {
    name: "AdminNews",
    setup() {
      // REFS E VARIÁVEIS
      const loading = ref(false);
      const news = ref([]);
      const displayedNews = ref([]);
      const itemsToShow = ref(10);
      const canLoadMore = ref(false);
  
      // CONTROLE DO DIÁLOGO E DO MENU
      const dialog = ref(false);
      const menu = ref(false);
  
      // NOTÍCIA EM EDIÇÃO
      const editedNews = ref({ date: null });

      const router = useRouter();
  
      // FUNÇÃO DE LOGOUT
      const logout = async () => {
      const auth = getAuth();
      try {
          await signOut(auth); // Faz o logout no Firebase
          router.push('/'); // Redireciona para a página inicial
          console.log('Usuário deslogado com sucesso');
      } catch (error) {
          console.error('Erro ao deslogar:', error);
      }
      };
  
      // FUNÇÃO PARA CARREGAR AS NOTÍCIAS
      const loadNews = async () => {
        loading.value = true;
        try {
          const q = query(
            collection(db, 'news'),
            orderBy('date', 'desc')
          );
          const querySnapshot = await getDocs(q);
          news.value = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));
  
          displayedNews.value = news.value.slice(0, itemsToShow.value);
          canLoadMore.value = news.value.length > displayedNews.value.length;
        } catch (error) {
          console.error("Erro ao carregar notícias:", error);
        } finally {
          loading.value = false;
        }
      };
  
      // CARREGAR MAIS NOTÍCIAS (PAGINAÇÃO SIMPLES)
      const loadMoreNews = () => {
        const newLimit = displayedNews.value.length + 10;
        displayedNews.value = news.value.slice(0, newLimit);
        canLoadMore.value = news.value.length > displayedNews.value.length;
      };
  
      // EDITAR NOTÍCIA
      const editNews = (item) => {
        editedNews.value = { ...item };
        // Se a data vier como Timestamp do Firestore, converter para Date
        if (editedNews.value.date && editedNews.value.date.toDate) {
          editedNews.value.date = editedNews.value.date.toDate();
        }
        menu.value = false;    // garante que o menu feche
        dialog.value = true;   // abre o diálogo de edição
      };
  
      // SALVAR NOTÍCIA (UPDATE)
      const saveNews = async () => {
        try {
          const newsRef = doc(db, 'news', editedNews.value.id);
          await updateDoc(newsRef, {
            ...editedNews.value,
            // Força a data ser do tipo Date ao salvar
            date:
              editedNews.value.date instanceof Date
                ? editedNews.value.date
                : new Date(editedNews.value.date),
          });
          dialog.value = false;
          loadNews();
        } catch (error) {
          console.error("Erro ao salvar notícia:", error);
        }
      };
  
      // REMOVER NOTÍCIA
      const deleteNews = async (id) => {
        try {
          const newsRef = doc(db, 'news', id);
          await deleteDoc(newsRef);
          loadNews();
        } catch (error) {
          console.error("Erro ao remover notícia:", error);
        }
      };
  
      // FORMATA A DATA PARA 'dd/mm/yyyy'
      const formatDate = (date) => {
        if (!date) return '';
        const publishedDate = date.toDate ? date.toDate() : new Date(date);
        return publishedDate.toLocaleDateString('pt-BR');
      };
  
      // FECHA O MENU ASSIM QUE O USUÁRIO ESCOLHE UMA DATA
      const handleDatePickerInput = () => {
        menu.value = false;
      };
  
      // CARREGA AS NOTÍCIAS AO MONTAR O COMPONENTE
      loadNews();
  
      // RETORNO DE TUDO QUE O TEMPLATE USA
      return {
        loading,
        news,
        displayedNews,
        itemsToShow,
        canLoadMore,
  
        dialog,
        menu,
        editedNews,
  
        logout,
        loadNews,
        loadMoreNews,
        editNews,
        saveNews,
        deleteNews,
  
        formatDate,
        handleDatePickerInput,
      };
    },
  };
  </script>
  
  <style scoped>
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  </style>
  