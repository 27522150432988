import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import AdminNews from '@/components/AdminNews.vue'; // Novo componente AdminPage
import LoginPage from '@/components/LoginPage.vue';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const requireAuth = (to, from, next) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      next(); // Usuário autenticado, permitir acesso
    } else {
      next('/login'); // Redirecionar para a HomePage
    }
  });
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminNews,
    beforeEnter: requireAuth // Middleware de autenticação
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
