<template>
    <v-container class="mt-10">
      <h1>Login</h1>
      <v-text-field
        label="Email"
        v-model="email"
        outlined
        dense
        class="mb-4"
      />
      <v-text-field
        label="Senha"
        v-model="password"
        type="password"
        outlined
        dense
        class="mb-4"
      />
      <v-btn @click="login" color="primary" block>
        Entrar
      </v-btn>
      <v-alert type="error" v-if="errorMessage" class="mt-4">
        {{ errorMessage }}
      </v-alert>
    </v-container>
  </template>
  
  <script>
  import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
  
  export default {
    name: "LoginPage",
    data() {
      return {
        email: "",
        password: "",
        errorMessage: null
      };
    },
    methods: {
      login() {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.email, this.password)
          .then(() => {
            this.$router.push('/admin'); // Redirecionar após login bem-sucedido
          })
          .catch((error) => {
            console.error("Erro no login:", error.message);
            this.errorMessage = "Erro ao fazer login. Verifique suas credenciais.";
          });
      }
    }
  };
  </script>
  
  <style scoped>
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  </style>
  